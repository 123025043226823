<template>
  <div class="main-content">
    <div class="header">
      <div class="container-fluid">
        <!-- Body -->
        <div class="header-body">
          <div class="row align-items-center">
            <div class="col">
              <h6 class="header-pretitle">
                {{ $t("menu.dashboard") }}
              </h6>
              <h1 class="header-title">
                {{ $t("menu.staff") }}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-container fluid>
      <b-row>
        <b-col cols="12">
          <div class="card">
            <div class="card-header">
              <!-- Title -->
              <h4 class="card-header-title"></h4>
              <div class="col-auto">
                <form class="mr-3">
                            <Select2
                            v-model="perPage"
                            :options="perPageOptions"
                            :settings="{
                                minimumResultsForSearch: -1,
                                containerCssClass:
                                'custom-select custom-select-sm form-control-flush',
                                dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                            }"
                            @change="paginatedList"                         
                            />
                        </form> 
              </div>
              <!-- Select -->
              <div class="col-auto">
              <form v-if="getActiveCompany">
                <b-dropdown
                  toggle-class="custom-select custom-select-sm form-control-flush"
                  variant="outline"
                  left
                  no-caret
                >
                  <template v-slot:button-content v-if="getActiveCompany.Name.length > 11">
                    {{ getActiveCompany.Name.slice(0, 10).concat('...')  }}
                  </template>
                  <template v-slot:button-content v-else>
                    {{ getActiveCompany.Name }}
                  </template>
                  <!-- v-for="company in getUserCompaniesManageable" -->
                  <b-dropdown-item
                    class="text-left text-truncate"
                    v-for="company in userCompanies"
                    v-bind:key="company.Id"
                    :to="{
                      name: 'company-staff',
                      params: { companyId: company.Id },
                    }"
                    @click="companySelectedStaff(company.Id)"
                    >{{ companyName(company.Name) }}</b-dropdown-item
                  >
                </b-dropdown>
              </form>
              </div>
              <div class="col-auto">
                <form>
                <Select2
                  v-model="sortOrder"
                  :options="sortOrderOptions"
                  :settings="{
                    minimumResultsForSearch: -1,
                    containerCssClass:
                      'custom-select custom-select-sm form-control-flush',
                    dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                  }"                         
                />                
              </form>
              </div>
             <!--  v-if="userRole != 'Installer'" -->
              <div class="col-auto">
                <b-button variant="primary" @click="createInvite" size="sm">
                  {{ $t("staff.invite") }}
                </b-button>
              </div>
            </div>
            <div class="card-header">
              <!-- Form -->
              <form>
                <div class="input-group input-group-flush input-group-merge">
                  <!-- Input -->

                  <b-form-input
                    :placeholder="$t('common.search')"
                    v-model="filter"
                    class="form-control form-control-prepended search"
                    type="search"
                  ></b-form-input>

                  <!-- Prepend -->
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <span class="fe fe-search"></span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="card-body">
              <b-pagination
                v-model="currentPage"
                :total-rows="getCompanyEmployeesFiltered.length"
                :per-page="perPage"
                aria-controls="my-table"
                class="pb-2"
              ></b-pagination>
              <zs-list-transition :showLoader="showLoader">
                <template v-slot:content>
                  <!-- v-for="(employee, index) in getCompanyEmployeesFiltered" -->
                  <employee-list-item
                    v-for="(employee, index) in paginatedList"
                    v-bind:key="index"
                    :employee="employee"
                    :company="getActiveCompany"
                  />
                </template>
                <template v-slot:empty>
                  <empty-list :title="$t('staff.no_staff')" />
                </template>
              </zs-list-transition>
              <b-pagination
                v-model="currentPage"
                :total-rows="getCompanyEmployeesFiltered.length"
                :per-page="perPage"
                aria-controls="my-table"
                class="pb-2"
              ></b-pagination>
            </div>
          </div>
          <!-- modals -->
          <div id="modals">
            <zs-company-delete-modal />
            <zs-delete-company-user-modal />
            <zs-delete-location-user-modal /> 
            <zs-company-invite-modal
              v-if="getActiveCompany"
              :company="getActiveCompany"
            /><zs-company-rerole-modal
              v-if="getActiveCompany"
              :company="getActiveCompany"
            />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ListTransition from "@/components/common/ListTransition";
import EmployeeListItem from "@/components/user/EmployeeListItem";
import DeleteCompanyModal from "@/components/modals/DeleteCompany";
import InviteCompanyEmployeeModal from "@/components/modals/InviteCompanyEmployeeAccordion";
import ChangeCompanyEmployeeRole from "@/components/modals/ChangeCompanyEmployeeRole";
import DeleteUserModal from "@/components/modals/DeleteUser";
import DeleteLocationUserModal from "@/components/modals/DeleteLocationUser";
import EmptyList from "@/components/common/EmptyList";
import Select2 from "@/components/common/Select2";

import { definedRoles, definedKeyRolesIdsArray } from "@/acl/roles";

import { mapGetters } from "vuex";

import {
  COMPANIES_GET,
  COMPANY_GET,
  COMPANY_GET_EMPLOYEES,
} from "@/store/types/companies";

import { USER_DECLINE_INVITE, USER_GET_ROLES } from "@/store/types/user";

import {
  M_COMPANY_FORM,
  M_COMPANY_INVITE_FORM,
  M_COMPANY_DELETE,
  M_COMPANY_USER_DELETE
} from "@/components/modals/types";

import modalMixin from "@/mixins/modalMixin";

export default {
  mixins: [modalMixin],
  data() {
    return {
      filter: "",
      sortOrder: 1,
      sortOrderOptions: [ 
        { id: 1, text: "Asc"},
        { id: 2, text: "Desc"},
      ],
      showLoader: true,
      perPage: 20,   
      currentPage: 1,
      perPageOptions: [
        { id: 10, text: "10 " + this.$t("menu.per_page") },
        { id: 20, text: "20 " + this.$t("menu.per_page") },
        { id: 50, text: "50 " + this.$t("menu.per_page") },
      ],
      userRole: "",
    };
  },
  computed: {
    ...mapGetters([
      "getUser",
      "getUserCompanies",
      "getActiveCompany",
      "getCompanyEmployees",
      "getUserRoles",
    ]),
    getUserCompaniesManageable() {
      return this.getUserCompanies.filter((item) =>
        this.$can("company-manage", item)
      );
    },
    getCompanyEmployeesFiltered() {
      return this.getCompanyEmployees
        .filter((item) => !definedKeyRolesIdsArray().includes(item.Role.Id))
        .filter((item) =>
          (item.User.FirstName + " " + item.User.LastName)
            .toLowerCase()
            .includes(this.filter.toLowerCase())
        )
        .sort((i1, i2) => {
          if (this.sortOrder == 1) {
            return i1.User.FirstName.toUpperCase() >
              i2.User.FirstName.toUpperCase()
              ? 1
              : -1;
          } else {
            return i1.User.FirstName.toUpperCase() <
              i2.User.FirstName.toUpperCase()
              ? 1
              : -1;
          }
        });
    },
    paginatedList(){
      return this.getCompanyEmployeesFiltered.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
    userCompanies() {            
            return this.getUserCompanies
            .filter((item) =>
                item.Name
                .toLowerCase()
                .includes(this.filter.toLowerCase())
            )
            .sort((i1, i2) => {
                return i1.Name.toUpperCase() > i2.Name.toUpperCase() ? 1 : -1;
            });
        },
  },
  created() {
    this.$store.dispatch(COMPANIES_GET);
    this.$store.dispatch(USER_GET_ROLES);
    this.initCompany(this.$route.params.companyId);
    // this.getLoggedInUserRoles();
  },
  methods: {
    companySelectedStaff(id){
      if(id){
        // window.location.reload();
      }      
    },
    getLoggedInUserRoles() {
            this.getUserRoles.filter((item) => item.CompanyId == this.$route.params.companyId)
            .map((item) => {
                if(item.Name == "Installer"){
                    return this.userRole = item.Name;
                }else{
                    return
                }
            });
        },
    initCompany(companyId) {
      this.$store.dispatch(COMPANY_GET, companyId);
      this.$store
        .dispatch(COMPANY_GET_EMPLOYEES, companyId)
        .then(() => (this.showLoader = false));
    },
    createCompany() {
      this.showModal(null, M_COMPANY_FORM);
    },
    createInvite() {
      this.showModal(null, M_COMPANY_INVITE_FORM);
    },    
    editCompany() {
      this.showModal({ company: this.getActiveCompany }, M_COMPANY_FORM);
    },
    deleteCompany() {
      this.showModal({ company: this.getActiveCompany }, M_COMPANY_DELETE);
    },
    getRoleObject(roleName) {
      return definedRoles[roleName];
    },
    companyName(name){
      if(name.length > 20){
        name = name.slice(0, 16).concat('...');
      }     
      return name 
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.initCompany(to.params.companyId);
    next();
  },
  components: {
    "zs-company-delete-modal": DeleteCompanyModal,
    "zs-company-invite-modal": InviteCompanyEmployeeModal,
    "zs-company-rerole-modal": ChangeCompanyEmployeeRole,
    "zs-delete-company-user-modal": DeleteUserModal,
    "zs-delete-location-user-modal": DeleteLocationUserModal,
    "zs-list-transition": ListTransition,
    EmployeeListItem,
    EmptyList,
    Select2,
  },
};
</script>

<style>
#card {
  margin: 1vh 0;
}
#createCompany {
  margin: 1vh 0;
}
</style>
