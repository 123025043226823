<template>
    <b-list-group-item>
        <div class="row align-items-center">
            <div class="col-auto">
                <!-- Avatar -->
                <router-link
                    v-if="employee.Location"
                    class="avatar avatar-lg"
                    :to="{
                        name: 'profile',
                        params: {
                            locationId: employee.Location.Id,
                            companyId: employee.Company.Id,
                            userId: employee.User.Id,
                        },
                    }"
                >
                    <img
                        v-if="employee.User.SmallImageUrl"
                        :src="employee.User.SmallImageUrl"
                        alt="..."
                        class="avatar-img rounded-circle"
                    />
                    <span class="avatar-title rounded-circle" v-else>
                        <!-- <i class="fi flaticon-user" style="margin-left: 2px !important;"></i> style="width: 32px; height: 32px; margin-left: 2px !important;" -->
                        <img :src="'/images/person-big_MyZesec_140dp.png'" style="width: 67px; height: 67px;"/>
                    </span>
                </router-link>
                <div class="avatar avatar-lg" v-else> 
                    <router-link
                    class="avatar avatar-lg"
                    v-if="employee.Company != null"
                    :to="{
                        name: 'profile-owner',
                        params: {
                            locationId: null,
                            companyId: employee.Company.Id,
                            userId: employee.User.Id,
                        },
                    }"
                >  
                    <img
                        v-if="employee.User.SmallImageUrl"
                        :src="employee.User.SmallImageUrl"
                        alt="..."
                        class="avatar-img rounded-circle"
                    />                    
                    <span class="avatar-title rounded-circle" v-else>
                        <!-- <i class="fi flaticon-user" style="margin-left: 3px !important;"></i> style="width: 32px; height: 32px; margin-left: 3px !important;"-->
                        <img :src="'/images/person-big_MyZesec_140dp.png'" style="width: 67px; height: 67px;"/>
                    </span>
                    </router-link>
                </div>
            </div>
            <div class="col ml-n2">
                <!-- Title -->
                <h4 class="mb-1" v-if="employee.Location">                  
                    <router-link
                        :to="{
                            name: 'profile',
                            params: {
                                locationId: employee.Location.Id,
                                companyId: employee.Company.Id,
                                userId: employee.User.Id,
                            },
                        }"
                        >{{ userFName(employee.User.FirstName) }}
                        {{ userLName(employee.User.LastName) }}</router-link
                    >
                </h4>
                <h4 class="mb-1" v-else>
                    <router-link
                        :to="{
                            name: 'profile-owner',
                            params: {
                                locationId: null,
                                companyId: employee.Company.Id,
                                userId: employee.User.Id,
                            },
                        }"
                        >{{ userFName(employee.User.FirstName) }} {{ userLName(employee.User.LastName) }}
                        </router-link>
                </h4>
                <!-- Text -->
                <p class="card-text small text-muted mb-1" v-if="employee.Location != null">
                    {{ employee.Location.Name }}
                </p>
                <p class="card-text small text-muted mb-1">
                    {{ roleName(employee.Role.Name) }}
                </p>
            </div>

            <div class="col-auto" v-if="(settingIcon) && ((userRole == 'CompanyOwner') || (userRole == 'CompanyManager')) && !roleFlag" >
                <!-- Dropdown -->
                <b-dropdown
                    variant="outline"
                    toggle-class="dropdown-ellipses dropdown-toggle"
                    no-caret
                    right
                    toggle-tag="a"
                    v-if="$can('cancel', getRoleObject(employee.Role.Name))"                  
                >
               <!--   -->
                    <template v-slot:button-content>
                        <!-- <i class="fe fe-more-vertical"></i> -->
                        <img :src="'/images/settings_grey_32x32dp.png'" style="width: 19px; height: 19px;"/>
                    </template>
                    <b-dropdown-item
                        class="text-left text-truncate"
                        @click="deleteUser(employee)"
                        >{{ $t("buttons.remove_staff") }}</b-dropdown-item
                    >
                    <!-- v-if="employee.User.Type != 'Light'" -->
                    <b-dropdown-item
                        class="text-left text-truncate"                        
                        @click="changeRole(employee)"
                        >{{ $t("staff.change_role") }}</b-dropdown-item
                    >
                </b-dropdown>
            </div>
            <div class="col-auto" v-if="settingIcon && ((userRole == 'CompanyOwner') || (userRole == 'CompanyManager')) && roleFlag" >
                <!-- Dropdown -->
                <b-dropdown
                    variant="outline"
                    toggle-class="dropdown-ellipses dropdown-toggle"
                    no-caret
                    right
                    toggle-tag="a"                
                >
               <!--   -->
                    <template v-slot:button-content>
                        <!-- <i class="fe fe-more-vertical"></i> -->
                        <img :src="'/images/settings_grey_32x32dp.png'" style="width: 19px; height: 19px;"/>
                    </template>
                    <b-dropdown-item
                        class="text-left text-truncate"
                        @click="deleteUser(employee)"
                        >{{ $t("buttons.remove_staff") }}</b-dropdown-item
                    >
                    <!-- v-if="employee.User.Type != 'Light'" -->
                    <b-dropdown-item
                        class="text-left text-truncate"                        
                        @click="changeRole(employee)"
                        >{{ $t("staff.change_role") }}</b-dropdown-item
                    >
                </b-dropdown>
            </div>
            <div class="col-auto" v-if="(settingIcon) && ((userRole == 'LocationManager') || (userRole == 'KeyManager') || (userRole == 'Installer'))">
                <!-- Dropdown -->
                <b-dropdown
                    variant="outline"
                    toggle-class="dropdown-ellipses dropdown-toggle"
                    no-caret
                    right
                    toggle-tag="a"
                    v-if="$can('cancel', getRoleObject(employee.Role.Name))"                  
                >
               <!--   -->
                    <template v-slot:button-content>
                        <!-- <i class="fe fe-more-vertical"></i> -->
                        <img :src="'/images/settings_grey_32x32dp.png'" style="width: 19px; height: 19px;"/>
                    </template>
                    <b-dropdown-item
                        class="text-left text-truncate"
                        :disabled="!itemsContains(employee.Location.Id)"
                        @click="deleteUser(employee)"
                        >{{ $t("buttons.remove_staff") }}</b-dropdown-item
                    >
                    <!-- v-if="employee.User.Type != 'Light'" -->
                    <b-dropdown-item
                        class="text-left text-truncate"                        
                        :disabled="(!itemsContains(employee.Location.Id)) || ((userRole == 'KeyManager') && (employee.Role.Name == 'Installer'))"
                        @click="changeRole(employee)"
                        >{{ $t("staff.change_role") }}</b-dropdown-item
                    >
                </b-dropdown>
            </div>
        </div>
    </b-list-group-item>
</template>
<script>
import {
    M_COMPANY_USER_DELETE,
    M_LOCATION_USER_DELETE,
    M_COMPANY_CHANGE_ROLE_FORM,
} from "@/components/modals/types";
import { mapGetters } from "vuex";
import { definedRoles, ROLE_COMPANY_MANAGER } from "@/acl/roles";

import modalMixin from "@/mixins/modalMixin";

export default {
    name: "user-list-item",
    mixins: [modalMixin],
    data() {
        return {
            keyRouteParams: false,
            userRole: "",
            settingIcon: false,
            locationId: null,
            userId: null,
            locationList: [],
            roleFlag: true,
            popupClose: false,
        };
    },
    computed: {
        ...mapGetters(["getUserRoles", "getUser"]),
    },
    props: ["employee", "company"],
    created(){
        this.getLoggedInUserRoles();
        if( this.userRole == "CompanyManager" || this.userRole == "LocationManager" || this.userRole == "KeyManager"){
            this.roleFlag = true           
        }      
        this.popupClose = this.$session.get("popUpClose");  
        // console.log("this.popupClose:"+this.popupClose)
        if(this.popupClose){
            this.popupClose = false;
            this.$session.set("popUpClose", false);
            // window.location.reload();
        }
    },
    methods: {        
        getLoggedInUserRoles() {
           this.userId = this.getUser.Id
           this.getUserRoles.filter((item) => (item.CompanyId == this.$route.params.companyId) && (item.User.Id == this.userId))
            .map((item) => {                 
                if(item.Name == "CompanyOwner" || item.Name == "CompanyManager" || item.Name == "LocationManager" || item.Name == "KeyManager"){
                    if(item.Name == "LocationManager" || item.Name == "KeyManager"){
                        // this.locationId = item.LocationId
                        this.locationList.push(item.LocationId)
                    }
                    return this.userRole = item.Name;
                }else{
                    return 
                }
            });
            
            if((this.userRole == "CompanyOwner") && (this.employee.Role.Name == "CompanyManager" || this.employee.Role.Name == "LocationManager" || this.employee.Role.Name == "KeyManager" || this.employee.Role.Name == "Installer")){
                this.settingIcon = true;
            }else if((this.userRole == "CompanyManager") && (this.employee.Role.Name == "CompanyManager" || this.employee.Role.Name == "LocationManager" || this.employee.Role.Name == "KeyManager" || this.employee.Role.Name == "Installer") && (this.userId != this.employee.User.Id)){
                this.settingIcon = true;
            }else{
                this.settingIcon = false;
            }
            
            for(let i=0; i<this.locationList.length; i++){
                this.locationId = this.locationList[i]  
                           
                if((this.userRole == "LocationManager") && (this.employee.Role.Name == "CompanyOwner" || this.employee.Role.Name == "CompanyManager")){
                    this.settingIcon = false;
                }else if((this.userRole == "LocationManager") && (this.employee.Role.Name == "KeyManager" || this.employee.Role.Name == "Installer") && (this.employee.Location != null)){
                    //  && (this.employee.Location.Id == this.locationList[i])
                    if((this.employee.Location.Id != null)){
                        this.settingIcon = true;
                    }else{
                        this.settingIcon = false;
                    }                             
                }else if((this.userRole == "KeyManager")  && (this.employee.Role.Name == "CompanyOwner" || this.employee.Role.Name == "CompanyManager" || this.employee.Role.Name == "LocationManager")){
                    this.settingIcon = false;
                }else if((this.userRole == "KeyManager") && (this.employee.Role.Name == "Installer") && (this.employee.Location != null)){
                    // console.log("userRole:"+this.userRole+", this.employee.Role.Name:"+this.employee.Role.Name)
                    if((this.employee.Location.Id != null) && (this.employee.Location.Id == this.locationList[i])){
                        this.settingIcon = true;
                    }else{
                        this.settingIcon = false;
                    }                            
                }else{
                    this.settingIcon = false;
                }
            }           
        },
        itemsContains(n) {
            return this.locationList.indexOf(n) > -1
        },
        deleteUser(user) {
            user.User["CompanyId"] = this.$route.params.companyId;
            if (user.Role.Name === "CompanyManager") {
                this.showModal({ user: user.User }, M_COMPANY_USER_DELETE);
            } else {
                this.showModal({ user: user }, M_LOCATION_USER_DELETE);                
            }
        },
        changeRole(user) {
            user.User["CompanyId"] = this.$route.params.companyId;
            this.showModal({ user: user.User }, M_COMPANY_CHANGE_ROLE_FORM);
        },
        getRoleObject(roleName) {
            return definedRoles[roleName];
        },
        userFName(FirstName){
            FirstName = this.employee.User.FirstName;
            if(FirstName === null || FirstName === "null" || FirstName === ""){
                FirstName = '';
            }            
            return FirstName;
        },
        userLName(LastName){
            LastName = this.employee.User.LastName;            
            if(LastName === null  || LastName === "null" || LastName === ""){
                LastName = '';
            }
            return LastName;
        }, 
        roleName(name){
            if(name == "CompanyOwner"){
                name = this.$t("permissions.role_owner");
            }else if(name == "CompanyManager"){
                name = this.$t("permissions.role_cm");
            }else if(name == "LocationManager"){
                name = this.$t("permissions.role_lm");
            }else if(name == "KeyManager"){
                name = this.$t("permissions.role_km");
            }else if(name == "Installer"){
                name = this.$t("permissions.role_installer");
            }
            return name;
        }
    },
};
</script>
<style scoped>
.list-group-item:last-child{
  overflow-y: auto !important;
}
.btn:focus{
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
}
</style>